<template>
    <div class="redirecting">
        Redirecting....
    </div>
</template>
<script>

    import playerService from '@/services/player-service'
    export default {
        name: 'TokenLoginRoute',

        created() {
            const code = this.$route.params.code
            if (code) {
                playerService.tokenLogin(code).then((res) => {
                    this.$store.commit('login', res.data.token)
                    this.goToHome()
                }).catch(error => {
                    if (error.response.data?.code === 'NO_PENDING_EMAIL_VERIFICATION') {
                        this.goToHome()
                    }
                })
            }
        },
        methods: {
            goToHome() {
                this.$store.dispatch('refreshCurrentUser')
                this.$router.push('/home')
            }
        }
    }
</script>

<style scoped>
.redirecting {
    color: var(--main-content-text);
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}
</style>
